/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/about-us/network/'], ['en', 'https://www.hear.com/about-us/network/'], ['en-US', 'https://www.hear.com/about-us/network/'], ['en-CA', 'https://ca.hear.com/about-us/network/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "canadas-top-doctors-of-audiology-and-hearing-specialists",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#canadas-top-doctors-of-audiology-and-hearing-specialists",
    "aria-label": "canadas top doctors of audiology and hearing specialists permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Canada’s top doctors of audiology and hearing specialists"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/customer-doing-hearing-test.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hear.com, you get to enjoy the benefits of our dual-expert approach. First, one of our expert consultants will speak to you on the phone about your hearing health. Second, at your first appointment, our trusted Partner Provider will use state-of-the-art equipment to evaluate your hearing and recommend devices that fit your lifestyle. We only work with the best audiologists, who have the most experience in the hearing aid industry. In fact, because of their continued support, our customer satisfaction rate is over 92%!")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "we-are-just-around-the-corner",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#we-are-just-around-the-corner",
    "aria-label": "we are just around the corner permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We are just around the corner"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ca_map.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have the largest network of Partner Providers in the world, so no matter where you live, we can help you find the nearest office. Our Partners come recommended by the leading specialists in the hearing aid industry and are known for their outstanding customer care. Additionally, our Partners regularly attend product trainings and audiology conferences to stay up-to-date with the latest hearing aid innovations. If hearing loss is stopping you from enjoying the simple pleasures of life, professional help is just a phone call away!")), "\n", React.createElement(ButtonCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
